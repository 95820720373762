import React, { useEffect, useRef, useState, useCallback } from 'react';
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import * as utils from "../Utils";
import avatarBackground from "../assets/img/avatarBackground.png";
import Subtitle from "./Subtitle";
import Thinking from "./status/Thinking";
import Listening from "./status/Listening";
import { useAvatarContext } from '../contexts/AvatarContext';
import AvatarControls from "./Controls";
import useWebSocket from 'react-use-websocket';

const Avatar = () => {
    // The "setWSAddress" will be used in a later revision of the code. It will allow the avatar to select different backends.
    /* eslint-disable-next-line */
    const [wsAddress, setWsAddress] = useState(`${window.location.origin.replace(/^https/, 'wss')}/ws`);
    const avatarSynthesizer = useRef(null);
    const avatarConnection = useRef(null);
    const removeVideoDiv = useRef(null);
    const audioRef = useRef(null);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const tmpCanvasRef = useRef(null);
    const previousAnimationFrameTimestamp = useRef(0);
    const speechRecognizer = useRef(null);
    const {
        isThinking,
        setIsThinking,
        isListening,
        setIsListening,
        setIsSpeaking,
        isAvatarStarted,
        setIsAvatarStarted,
        avatarSelection,
        avatarCaption,
        setAvatarCaption,
        avatarVoice,
        setEvents,
        currentEventId,
        setLastSpeakTime,
        isAgentReady,
        setIsAgentReady,
        setIsAvatarLoading,
        iceCredentials,
        setIceCredentials
    } = useAvatarContext();

    // WebSocket connection to the Langchain API
    const { sendJsonMessage } = useWebSocket(wsAddress, {
        onMessage: (message) => {
            const data = JSON.parse(message.data);
            console.log(data);
            switch (data.type) {
                case "agentResponse":
                    speak(data.result["output"]);
                    break;
                case "setToken":
                    setIceCredentials(data.iceServers);
                    setEvents(data.events);
                    sendJsonMessage({ type: "buildAgent", "eventId": currentEventId });
                    break;
                case "agentCreated":
                    setIsAgentReady(true);
                    console.log("Agent is ready to talk.");
                    break;
                default:
                    console.log(`[${new Date().toISOString()}]: Unknown message type: ${data.type}`);
                    break;
            }
        },
        onOpen: (event) => {
            console.log('Connection opened');
            sendJsonMessage({ type: "token" });
        },
        onClose: (event) => {
            console.log('Connection closed');
        },
        onError: (event) => {
            console.log('Error:', event);
        },
        share: true,
        shouldReconnect: (closeEvent) => true
    });

    const queryLangchainAgent = useCallback((query) => {
        if (!isAgentReady) return
        sendJsonMessage({
            type: "agentCall",
            input: query
        });
    }, [sendJsonMessage, isAgentReady]);


    function speak(text, endingSilenceMs = 0) {
        if (!avatarSynthesizer.current) {
            console.error("Avatar Synthesizer is not ready.")
            return;
        };

        setIsSpeaking(true);
        setAvatarCaption({ role: "agent", content: text });
        const ssml = utils.generateSSML(text, avatarVoice, endingSilenceMs);

        console.log(`[${new Date().toISOString()}]: Speaking...${ssml}`);

        avatarSynthesizer.current.speakSsmlAsync(ssml).then((result) => {
            if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
                setIsSpeaking(false);
            } else {
                if (result.reason === SpeechSDK.ResultReason.Canceled) {
                    let cancellationDetails = SpeechSDK.CancellationDetails.fromResult(result);
                    if (cancellationDetails.reason === SpeechSDK.CancellationReason.Error) {
                        console.log(cancellationDetails)
                        console.error(`Error occurred while speaking the SSML: [ ${cancellationDetails.errorDetails} ]`);
                    };
                };
            };
        }).catch((error) => {
            console.error(`Error occurred while speaking the SSML: [ ${error} ]`);
        });
    };

    const stopRecognition = useCallback(() => {
        speechRecognizer.current.stopContinuousRecognitionAsync(() => {
            console.log(`[${new Date().toISOString()}]: Speech recognition stopped.`);
            setIsListening(false);
        }).catch((err) => {
            console.error(err);
        });
    }, [speechRecognizer, setIsListening]);

    const handleStartRecognitionButton = (e) => {
        e.preventDefault();
        setIsListening(true)
        speechRecognizer.current.startContinuousRecognitionAsync(() => {
            console.log(`[${new Date().toISOString()}]: Speech Recognition Started`);
        }, (err) => {
            console.log(err)
        });
    };

    const handleStopSpeakingButton = (e) => {
        e.preventDefault();
        stopSpeaking();
    };

    const handleStopRecognitionButton = (e) => {
        e.preventDefault();
        stopRecognition();
    };

    // Callback function to handle errors from TTS Avatar API
    const error_cb = useCallback((result) => {
        let cancellationDetails = SpeechSDK.CancellationDetails.fromResult(result);
        console.log(`Error occurred in the Avatar service: ${cancellationDetails.errorDetails}`);
    }, []);

    // Callback function to handle the response from TTS Avatar API
    const complete_cb = useCallback((result) => {
        const sdp = result.properties.getProperty(SpeechSDK.PropertyId.TalkingAvatarService_WebRTC_SDP);

        if (sdp === undefined) {
            console.log(`[${new Date().toISOString()}] Failed to get remote SDP. The avatar instance is temporarily unavailable. Result ID: ${result.resultId} `);
        };
        setTimeout(() => {
            avatarConnection.current.setRemoteDescription(new RTCSessionDescription(JSON.parse(atob(sdp))));
        }, 2000);
    }, []);

    const makeBackgroundTransparent = useCallback((timestamp) => {
        // Throttle the frame rate to 30 FPS to reduce CPU usage
        if (timestamp - previousAnimationFrameTimestamp.current > 33.33) {
            const video = videoRef.current;
            const tmpCanvas = tmpCanvasRef.current;
            const tmpCanvasContext = tmpCanvas.getContext("2d", {
                willReadFrequently: true,
            });

            tmpCanvasContext.drawImage(
                video,
                0,
                0,
                video.videoWidth,
                video.videoHeight
            );

            if (video.videoWidth > 0) {
                let frame = tmpCanvasContext.getImageData(
                    0,
                    0,
                    video.videoWidth,
                    video.videoHeight
                );

                const data = frame.data;
                const length = data.length;

                for (let i = 0; i < length; i += 4) {
                    let r = data[i];
                    let g = data[i + 1];
                    let b = data[i + 2];

                    if (g - 150 > r + b) {
                        // Set alpha to 0 for pixels that are close to green
                        data[i + 3] = 0;
                    } else if (g + g > r + b) {
                        // Reduce green part of the green pixels to avoid green edge issue
                        let adjustment = (g - (r + b) / 2) / 3;
                        r += adjustment;
                        g -= adjustment * 2;
                        b += adjustment;
                        data[i] = r;
                        data[i + 1] = g;
                        data[i + 2] = b;
                        // Reduce alpha part for green pixels to make the edge smoother
                        data[i + 3] = Math.max(0, 255 - adjustment * 4);
                    }
                }

                const canvas = canvasRef.current;
                const canvasContext = canvas.getContext("2d");
                canvasContext.putImageData(frame, 0, 0);
            }

            previousAnimationFrameTimestamp.current = timestamp;
        }

        window.requestAnimationFrame(makeBackgroundTransparent);
    }, []);

    const stopSpeaking = useCallback(() => {
        setIsSpeaking(false);
        avatarSynthesizer.current.stopSpeakingAsync(() => {
            console.log(`[${new Date().toISOString()}]: Avatar stopped speaking.`);
        }, (error) => {
            console.error(`Error occurred while stopping the Avatar: [ ${error} ]`);
        }
        );
    }, [setIsSpeaking, avatarSynthesizer]);

    const startAvatar = useCallback(() => {
        if (!isAvatarStarted && iceCredentials) {
            console.log(`[${new Date().toISOString()}]: Starting Avatar...`);
            setIsAvatarLoading(true);
            avatarConnection.current = new RTCPeerConnection(iceCredentials);
            avatarConnection.current.addEventListener("track", (event) => {
                if (event.track.kind === "audio") {
                    audioRef.current.srcObject = event.streams[0];
                } else if (event.track.kind === "video") {
                    videoRef.current.srcObject = event.streams[0];
                    removeVideoDiv.current.hidden = true;
                    canvasRef.current.hidden = false;
                    videoRef.current.addEventListener("play", () => {
                        removeVideoDiv.current.style.width = videoRef.current.videoWidth + "px";
                        window.requestAnimationFrame(makeBackgroundTransparent);
                        setIsAvatarStarted(true);
                        setIsAvatarLoading(false);
                    });
                    videoRef.current.onplaying = () => {
                        console.log(`WebRTC ${event.track.kind} channel connected.`);
                    };
                };
            });

            // For troubleshooting purposes. Can be removed in production.
            avatarConnection.current.addEventListener("iceconnectionstatechange", (event) => {
                switch (event.currentTarget.iceConnectionState) {
                    case "connected":
                        console.log(`ICE connection state is ${event.currentTarget.iceConnectionState}.`);
                        break;
                    case "disconnected":
                        console.log(`ICE connection state is ${event.currentTarget.iceConnectionState}.`);
                        setIsAvatarStarted(false);
                        break;
                    case 'closed':
                        console.log(`ICE connection state is ${event.currentTarget.iceConnectionState}.`);
                        setIsAvatarStarted(false);
                        break;
                    case 'checking':
                        console.log(`ICE connection state is ${event.currentTarget.iceConnectionState}.`);
                        break;
                    default:
                        console.log('Unknown ICE connection state:', avatarConnection.current.iceConnectionState);
                        break;
                };
            });

            avatarConnection.current.addTransceiver("video", { direction: "sendrecv" });
            avatarConnection.current.addTransceiver("audio", { direction: "sendrecv" });

            avatarSynthesizer.current.startAvatarAsync(avatarConnection.current, complete_cb, error_cb).then((r) => {
                if (r.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
                    console.log(`[${new Date().toISOString()}]: Avatar Connected...`);
                } else {
                    console.error(`[${new Date().toISOString()}]: Failed to Avatar Start - ${r.reason}`);
                    console.error(`[${new Date().toISOString()}]: ${JSON.stringify(r)}`);
                    if (r.reason === SpeechSDK.ResultReason.Canceled) {
                        let cancellationDetails = SpeechSDK.CancellationDetails.fromResult(r);
                        if (cancellationDetails.reason === SpeechSDK.CancellationReason.Error) {
                            console.error(`[${new Date().toISOString()}]: ${cancellationDetails.errorDetails}`);
                        };
                    };
                };
            }).catch((error) => {
                console.error(`[${new Date().toISOString()}]: ${error.message}`);
            });

            // Determine when the avatar is speaking or not
            avatarSynthesizer.current.avatarEventReceived = (s, e) => {
                var offsetMessage = ", offset from session start: " + e.offset / 10000 + "ms."
                if (e.offset === 0) {
                    offsetMessage = ""
                };
                console.log("Event received: " + e.description + offsetMessage)

                if (e.privDescription === "TurnStart") {
                    setIsSpeaking(true);
                    setIsThinking(false);
                    stopRecognition();
                } else if (e.privDescription === "TurnEnd") {
                    setIsSpeaking(false);
                    setLastSpeakTime(Date.now());
                };
            };
        };
    }, [setIsSpeaking, makeBackgroundTransparent, iceCredentials, setIsAvatarLoading, setLastSpeakTime, isAvatarStarted, setIsAvatarStarted, avatarSynthesizer, complete_cb, error_cb, setIsThinking, stopRecognition]);


    const handleStartAvatarButton = useCallback(() => {
        if (!isAvatarStarted && iceCredentials) {
            startAvatar();
        };
    }, [isAvatarStarted, iceCredentials, startAvatar]);



    useEffect (()=> {
        let videoElement = videoRef.current;
        if (videoElement !== null && videoElement !== undefined && isAvatarStarted) {
            let videoTime = videoElement.currentTime;
            setTimeout(() => {
                // Check whether the video time is advancing
                if (videoElement.currentTime === videoTime) {
                    // Check whether the session is active to avoid duplicated triggering reconnect
                    if (isAvatarStarted) {
                        setIsAvatarStarted(false)
                        console.log(`[${(new Date()).toISOString()}] The video stream got disconnected, need reconnect.`)
                        startAvatar()
                    }
                }
            }, 2000)
        };

    }, [isAvatarStarted, startAvatar, setIsAvatarStarted]);

    useEffect(() => {
        if (isAgentReady && isAvatarStarted && currentEventId) {
            sendJsonMessage({ type: "agentCall", "input": "Hi, Introduce yourself." });
        };
    }, [isAgentReady, isAvatarStarted, currentEventId, sendJsonMessage]);

    useEffect(() => {
        try {
            // Languages for Azure Recognizer
            const supportedLanguages = ["en-US", "es-US", "de-DE", "zh-CN", "ar-AE", "ja-JP", "pt-BR", "fr-CA"];

            // Text-to-Speech
            const speechSynthesisConfig = SpeechSDK.SpeechConfig.fromSubscription(process.env.REACT_APP_SPEECH_KEY, process.env.REACT_APP_SPEECH_REGION);
            speechSynthesisConfig.speechSynthesisVoiceName = avatarVoice;
            speechSynthesisConfig.speechSynthesisLanguage = "en-US";

            // Set up the avatar to crop the video feed to fit into a portrait mode
            const avatarVideoFormat = new SpeechSDK.AvatarVideoFormat();
            avatarVideoFormat.setCropRange(new SpeechSDK.Coordinate(600, 0), new SpeechSDK.Coordinate(1320, 1080));

            // You can change the avatar here as well as the position of the avatar
            const avatarConfig = new SpeechSDK.AvatarConfig(avatarSelection.character, avatarSelection.style, avatarVideoFormat);

            // Set the background color of the avatar to green screen
            avatarConfig.subtitleType = "soft_embedded";
            avatarConfig.backgroundColor = "#00FF00FF";

            // Setup the avatar synthesizer
            avatarSynthesizer.current = new SpeechSDK.AvatarSynthesizer(speechSynthesisConfig, avatarConfig);

            // Speech Recognition
            const speechRecognitionConfig = SpeechSDK.SpeechConfig.fromSubscription(process.env.REACT_APP_SPEECH_KEY, process.env.REACT_APP_SPEECH_REGION);

            // Set the language for the speech recognition
            const autoDetectSourceLanguageConfig = SpeechSDK.AutoDetectSourceLanguageConfig.fromLanguages(supportedLanguages);

            // Set up the audio configuration from microphone
            const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();

            // Set up the speech recognizer
            speechRecognitionConfig.setProperty(SpeechSDK.PropertyId.SpeechServiceConnection_LanguageIdMode, "Continuous");

            speechRecognizer.current = SpeechSDK.SpeechRecognizer.FromConfig(speechRecognitionConfig, autoDetectSourceLanguageConfig, audioConfig);

            //TODO: Need to figure out why the recognizer passes the word "Play" when continuous listening is enabled.
            speechRecognizer.current.recognized = (s, e) => {
                if (e.result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
                    setIsListening(false);
                    stopRecognition();
                    let userQuery = e.result.text.trim();

                    // Check if the user query contains the word "play" and is less than 6 characters
                    if (userQuery.toLowerCase().includes("play") && userQuery.length < 6) {
                        return;
                    };

                    // Send recognized text to API / Langchain
                    setAvatarCaption({ role: "user", content: userQuery });
                    queryLangchainAgent(userQuery);
                } else {
                    console.error(e.result.reason);
                };
            };

            speechRecognizer.current.canceled = (s, e) => {
                setIsListening(false);
            };

            // Determine when the avatar is speaking or not
            avatarSynthesizer.current.avatarEventReceived = (s, e) => {
                if (e.privDescription === "TurnStart") {
                    setIsSpeaking(true);
                    setIsThinking(false);
                } else if (e.privDescription === "TurnEnd") {
                    setIsSpeaking(false);
                    setLastSpeakTime(Date.now());
                };
            };
        } catch (err) {
            console.error(err);
        };
    }, [speechRecognizer, avatarSelection, setLastSpeakTime, setAvatarCaption, setIsAgentReady, stopRecognition, avatarVoice, setIsListening, setIsThinking, avatarSynthesizer, setIsSpeaking, queryLangchainAgent]);


    return (
        <div
            id="canvasContainer"
            style={{ backgroundImage: `url(${avatarBackground})` }}
            className="h-full w-full bg-no-repeat bg-cover relative overflow-hidden flex items-center justify-center">
            <div id="remoteVideo" ref={removeVideoDiv} className="h-full w-full">
                <video id="video" ref={videoRef} autoPlay playsInline className="h-full w-full"></video>
                <audio id="remoteAudio" ref={audioRef} autoPlay></audio>
            </div>
            <canvas
                id="canvas"
                hidden
                ref={canvasRef}
                width={720}
                height={1080}
            ></canvas>
            <canvas
                id="tmpCanvas"
                hidden
                width={720}
                height={1080}
                ref={tmpCanvasRef}
            ></canvas>
            {isListening && !isThinking && <Listening />}
            {isThinking && !isListening && <Thinking />}
            {avatarCaption && <Subtitle message={avatarCaption} />}
            <AvatarControls
                handleStartAvatarButton={handleStartAvatarButton}
                handleStartRecognitionButton={handleStartRecognitionButton}
                handleStopSpeakingButton={handleStopSpeakingButton}
                handleStopRecognitionButton={handleStopRecognitionButton}
                handleResetLangchain={() => sendJsonMessage({ "type": "resetAgent" })}
            />
        </div>
    )
};

export default Avatar;