import React from 'react';

const OrderGrid = ({ children }) => {
    return (
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-4 3xl:grid-cols-6 p-4">
            {children}
        </div>
    );
}

export default OrderGrid;