import React, { useRef, useState, useCallback } from 'react';
import './App.css';
import { useEffect } from 'react';
import OrderGrid from './components/orders/OrderGrid';
import Header from './components/orders/Header';
import ConfigModal from './components/orders/ConfigModal';
const OrderCard = React.lazy(() => import('./components/orders/OrderCard'));

function OrderDisplay() {
    const orderSocket = useRef(null);
    const [wsStatus, setWsStatus] = useState('Disconnected');
    const [configOpen, setConfigOpen] = useState(true);
    const [orderList, setOrderList] = useState([])
    const [events, setEvents] = useState([]);
    const [currentEventId, setCurrentEventId] = useState("");
    const [filterCompleted, setFilterCompleted] = useState(true);

    const toggleConfigOpen = () => {
        setConfigOpen(!configOpen);
    };


    const handleSaveEventId = (eventId) => {
        setCurrentEventId(eventId);
        toggleConfigOpen();
    };


    const handleFilterOrdersCheckbox = (e) => {
        setFilterCompleted(e.target.checked);
    };



    // This keeps the connection alive by sending a ping every 30 seconds
    const runEveryThirtySeconds = useCallback(() => {
        if (orderSocket.current.readyState === WebSocket.OPEN) {
            orderSocket.current.send(JSON.stringify({ type: "ping" }));
        };
    }, []);

    useEffect(() => {
        const intervalId = setInterval(runEveryThirtySeconds, 30000);
        return () => clearInterval(intervalId);
    }, [runEveryThirtySeconds]);

    useEffect(() => {
        if (orderSocket.current && orderSocket.current.readyState === WebSocket.OPEN) {
            return;
        };

        const wsAddress = `${window.location.origin.replace(/^https/, 'wss')}/api`;
        // const wsAddress = new WebSocket("ws://192.168.86.190:8080/ws");
        orderSocket.current = new WebSocket(wsAddress);

        orderSocket.current.onopen = () => {
            setWsStatus('Connected');
            orderSocket.current.send(JSON.stringify({ type: 'getEvents' }));
        };

        orderSocket.current.onmessage = (event) => {
            const message = JSON.parse(event.data);
            if (message.type === 'setOrders') {
                setOrderList(message.orders);
            } else if (message.type === 'setEvents') {
                setEvents(message.events);
            } else if (message.type === 'eventIdSet') {
                setOrderList(message.orders);
            } else if (message.type === 'pong') {
                console.log('pong');
            };
        };

        orderSocket.current.onerror = (error) => {
            setWsStatus('Disconnected');
        };

        orderSocket.current.onclose = () => {
            setWsStatus('Disconnected');
        };

        // Clean up WebSocket connection on component unmount
        return () => {
            if (orderSocket.current) {
                orderSocket.current.close();
            }
        };
    }, []);

    useEffect(() => {
        if (currentEventId && orderSocket.current.readyState === WebSocket.OPEN) {
            orderSocket.current.send(JSON.stringify({ type: 'setEventId', eventId: currentEventId }));
        };
    }, [currentEventId]);

    return (
        <div className="App bg-slate-50 min-h-screen">
            <Header status={wsStatus} events={events} toggleConfigOpen={() => toggleConfigOpen(true)} />
            <OrderGrid>
                {orderList
                    .filter(order => !filterCompleted || order.status !== 'completed')
                    .map((order) => (
                        <OrderCard key={order.orderNumber} order={order} ws={orderSocket.current} />
                    ))}
            </OrderGrid>
            <ConfigModal
                isOpen={configOpen}
                onClose={() => toggleConfigOpen(false)}
                onSave={handleSaveEventId}
                events={events}
                currentEventId={currentEventId}
                orderList={orderList}
                filterCompleted={filterCompleted}
                setFilterCompleted={handleFilterOrdersCheckbox} />
        </div>
    );
};

export default OrderDisplay;
