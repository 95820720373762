import React from 'react';

const ControlButton = ({ icon, action, tooltip, disabled, glow, loading }) => {

    return (


        <button
            className={`bg-[#cc0000] text-white font-bold p-2 rounded-full opacity-80 w-10 h-10 items-center justify-center flex hover:opacity-100 active:opacity-60 transition-opacity duration-300 ${disabled ? 'bg-gray-400 cursor-not-allowed' : ''} ${glow && !disabled ? 'glowing-ring-yellow' : ''}`}
            onClick={action}
            title={tooltip}
            disabled={disabled || loading}
        >
            {loading ? (
                <div className="loader border-4 border-t-4 border-gray-300 rounded-full w-6 h-6 border-t-white animate-spin"></div>
            ) : (
                icon
            )}
        </button>
    )
};

export default ControlButton;