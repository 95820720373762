// src/Routes.js
import React from 'react';
import Avatar from './App';
import Orders from './Orders';
import WebSocketStatus from './Test';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const AppRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Avatar />} />
                <Route path="/orders" element={<Orders />} />
            </Routes>
        </Router>
    );
};

export default AppRoutes;