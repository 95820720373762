import './App.css';
import React, { useRef, useState } from "react";
import { AvatarProvider } from './contexts/AvatarContext';
import Avatar from './components/Avatar';
import ConfigModal from './components/Config';


function App() {
  const [wsURL, setWsURL] = useState(`${window.location.origin.replace(/^https/, 'wss')}/ws`);
  const avatarRef = useRef(null);

  useState(() => {
    if (process.env.NODE_ENV === "production") {
      setWsURL(`${window.location.origin.replace(/^https/, 'wss')}/ws`)
    } else {
      setWsURL(`${window.location.origin.replace(/^http/, 'ws').replace("3000", "8080")}/ws`);
    };
    setWsURL(wsURL);
  }, [])


  return (
    <div className="flex flex-col dark text-foreground z-0 items-center justify-center h-screen min-h-screen bg-stone-900">
      <div className="overflow-hidden h-[1080px] w-[720px] portrait:h-full portrait:w-full">
        <div className="flex items-center justify-center overflow-hidden h-full w-full">
            <AvatarProvider>
              <Avatar ref={avatarRef} wsURL={wsURL} />
              <ConfigModal />
            </AvatarProvider>
        </div>
      </div>
    </div>
  );
}

export default App;
