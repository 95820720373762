import React from 'react';
import CDWLogo from '../../assets/cdw-logo-white.png'; // Replace with your actual logo file path
import { MdOutlineSettings } from "react-icons/md";

const Header = ({ status, toggleConfigOpen }) => {
    const getStatusColor = () => {
        switch (status) {
            case 'Connected':
                return 'bg-green-500';
            case 'Disconnected':
                return 'bg-red-500';
            case 'Error':
                return 'bg-yellow-500';
            default:
                return 'bg-gray-500';
        }
    };

    return (
        <header className="flex items-center justify-between px-6 py-4 bg-gradient-to-r from-cdw-red-start via-cdw-red-middle to-cdw-red-end text-white">
            <div className="flex items-center">
                <img src={CDWLogo} alt="Cafe' CDW Logo" className="h-12 mr-4" />
            </div>
            <div className="flex-1 flex flex-col items-center justify-center">
                <h1 className="text-xl sm:text-2xl md:text-3xl font-bold font-source-sans-extrabold">Coffee Down the Wire</h1>
                <div className="flex items-center mt-2">
                    <div className={`w-4 h-4 rounded-full ${getStatusColor()} mr-2`}></div>
                    <span className="text-sm">{status}</span>
                </div>
            </div>
            <div className="flex top-4 right-4 z-10">
                <MdOutlineSettings className="text-2xl text-white cursor-pointer" onClick={toggleConfigOpen} size={30}/>
            </div>

        </header>
    );
};

export default Header;