import React, { useEffect, useMemo } from 'react';
import {
    FaMicrophone,
    FaCirclePlay,
    FaCirclePause,
    FaRotate,
    FaCircleStop,
} from "react-icons/fa6";
import ControlButton from "./controls/ControlButton";
import { useAvatarContext } from '../contexts/AvatarContext'; // Adjust the import path as necessary

const Controls = ({handleStartAvatarButton, handleStartRecognitionButton, handleStopSpeakingButton, handleStopRecognitionButton, handleResetLangchain}) => {
    const {
        isSpeaking,
        isThinking,
        isListening,
        isAgentReady,
        isAvatarStarted,
        isAvatarLoading,
        currentEventId,
        startButtonEnabled,
        recognitionButtonEnabled,
        setStartButtonEnabled,
        setRecognitionButtonEnabled
    } = useAvatarContext();

    useEffect(() => {
        setStartButtonEnabled(currentEventId && !isAvatarStarted && isAgentReady);
        if (isAvatarStarted) {
            setStartButtonEnabled(false);
        };
    }, [currentEventId, isAvatarStarted, isAgentReady, setStartButtonEnabled]);

    useEffect(() => {
        setRecognitionButtonEnabled(isAvatarStarted && !isSpeaking && !isThinking && !isListening);
    }, [isSpeaking, isThinking, isAvatarStarted, setRecognitionButtonEnabled, isListening]);


    const buttons = useMemo(() => [
        { icon: <FaCirclePlay />, key: 'start', action: handleStartAvatarButton, tooltip: 'Start Avatar', disabled: !startButtonEnabled, glow: true, loading: isAvatarLoading},
        { icon: <FaMicrophone />, key: 'microphone', action: handleStartRecognitionButton, tooltip: 'Start Recognition', disabled: !recognitionButtonEnabled, glow: true, loading: false },
        { icon: <FaCirclePause />, key: 'pause', action: handleStopRecognitionButton, tooltip: 'Pause Recognition', disabled: !isListening, glow: false, loading: false },
        { icon: <FaCircleStop />, key: 'stop', action: handleStopSpeakingButton, tooltip: 'Stop Speaking', disabled: !isSpeaking, glow: true, loading: false },
        { icon: <FaRotate />, key: 'reload', action: handleResetLangchain, tooltip: 'New Agent Session', disabled: false, glow: false, loading: false },
    ], [startButtonEnabled, recognitionButtonEnabled, handleStartRecognitionButton, handleStopRecognitionButton, isListening ,isSpeaking, isAvatarLoading, handleResetLangchain, handleStartAvatarButton, handleStopSpeakingButton]);

    return (
        <div className="absolute top-1/3 left-2 flex items-center justify-center">
            <ul className="flex flex-col items-center">
                {buttons.map(button => (
                    <li key={button.key} className="p-1.5">
                        <ControlButton
                            icon={button.icon}
                            action={button.action}
                            tooltip={button.tooltip}
                            disabled={button.disabled}
                            glow={button.glow}
                            loading={button.loading} />
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Controls;