import React from 'react';


const Thinking = () => {
    return (
        <div className="absolute align-middle top-1 right-2 mt-2 ml-2 text-[#cc0000] text-md font-base flex items-center w-[6rem]">
            <div className="loader border-4 border-t-4 border-gray-300 rounded-full w-6 h-6 border-t-[#cc0000] animate-spin p-2"></div>
            <span className="ml-2 px-6 w-8  text-[#cc0000]">Thinking...</span>
        </div>
    )

};

export default Thinking;