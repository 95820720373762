import React from 'react';
import PropTypes from 'prop-types';

const ConfigModal = ({ isOpen, onClose, onSave, events, currentEventId, orderList, filterCompleted, setFilterCompleted }) => {
    if (!isOpen) {
        return null;
    }

    const totalOrders = orderList.length;
    const completedOrders = orderList.filter(order => order.status === 'completed').length;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
            <div className="bg-white p-6 rounded-lg shadow-2xl border border-gray-300 w-full max-w-xl mx-auto">
                <h2 className="text-xl font-semibold mb-4 text-left">Configuration</h2>

                {/* Event Selection */}
                <div className="mb-4 text-left">
                    <label htmlFor="event-select" className="block text-sm font-medium mb-2">Select Event:</label>
                    <div className="w-full max-w-md">
                        <select
                            id="event-select"
                            className="w-full px-3 py-2 border rounded"
                            value={currentEventId}
                            onChange={(e) => onSave(e.target.value)}
                        >
                            <option value="" disabled>Select an event</option>
                            {events.map(event => (
                                <option key={event._id} value={event._id}>
                                    {event.eventName}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                {/* Filter Completed Checkbox */}
                <div className="mb-4 flex items-center">
                    <label htmlFor="show-completed" className="text-sm font-medium mr-2">Filter Completed:</label>
                    <input
                        id="show-completed"
                        type="checkbox"
                        checked={filterCompleted}
                        onChange={setFilterCompleted}
                        className="mr-2"
                    />
                </div>

                {/* Order Counts */}
                <div className="mb-4 text-left">
                    <p className="text-sm">Completed Orders: {completedOrders}</p>
                    <p className="text-sm">Total Orders: {totalOrders}</p>
                </div>

                <div className="flex justify-end">
                    <button
                        onClick={onClose}
                        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

ConfigModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    events: PropTypes.arrayOf(PropTypes.shape({
        _id: PropTypes.string.isRequired,
        eventName: PropTypes.string.isRequired,
    })).isRequired,
    currentEventId: PropTypes.string.isRequired,
    orderList: PropTypes.arrayOf(PropTypes.shape({
        status: PropTypes.string.isRequired,
    })).isRequired,
    filterCompleted: PropTypes.bool.isRequired,
    setFilterCompleted: PropTypes.func.isRequired,
};

export default ConfigModal;