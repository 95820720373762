import PropTypes from 'prop-types';
import { useAvatarContext } from '../contexts/AvatarContext';
import { useState, useEffect } from 'react';

const ConfigModal = () => {
    const { events, currentEventId, setCurrentEventId } = useAvatarContext();
    const [selectedEventId, setSelectedEventId] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (events && !currentEventId) {
            setIsOpen(true);
        }
    }, [isOpen, events, currentEventId]);

    const handleSave = () => {
        setCurrentEventId(selectedEventId);
        setIsOpen(false);
    };

    const toggleModal = () => {
        setIsOpen(false);
    };

    return (
        <div className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 ${isOpen ? 'block' : 'hidden'}`}>
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto relative">
                <button
                    onClick={toggleModal}
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                >
                    &times;
                </button>
                <h2 className="text-xl font-bold mb-4">Configuration</h2>
                <div className="mb-4">
                    <label className="block text-gray-800 p-2">Select Event</label>
                    <select
                        className="w-full px-3 py-2 border rounded"
                        value={selectedEventId}
                        onChange={(e) => setSelectedEventId(e.target.value)}
                    >
                        <option value="" disabled>Select an event</option>
                        {events.map(event => (
                            <option key={event._id} value={event._id}>
                                {event.eventName}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mb-4 flex items-center">
                    <label htmlFor="continuous-listening" className="text-sm font-medium mr-2">Continuous Listening:</label>
                    <input
                        id="continuous-listening"
                        type="checkbox"
                    />
                </div>
                <div className="flex justify-end">
                    <button onClick={handleSave} className="bg-blue-500 text-white px-4 py-2 rounded">Save</button>
                </div>
            </div>
        </div>
    );
};

ConfigModal.propTypes = {
    events: PropTypes.array.isRequired,
    currentEventId: PropTypes.string,
    setCurrentEventId: PropTypes.func.isRequired
};

export default ConfigModal;