// AvatarContext.js
import React, { createContext, useState, useContext } from 'react';

export const AvatarContext = createContext();

export const AvatarProvider = ({ children }) => {
    const [isThinking, setIsThinking] = useState(false);
    const [isListening, setIsListening] = useState(false);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [isAgentReady, setIsAgentReady] = useState(false);
    const [isAvatarStarted, setIsAvatarStarted] = useState(false);
    const [isAvatarLoading, setIsAvatarLoading] = useState(false);
    const [avatarSelection, setAvatarSelection] = useState({ "character": "meg", "style": "formal" }); // State for the avatar selection
    const [avatarCaption, setAvatarCaption] = useState(null); // State for the caption displayed on the screen
    const [avatarVoice, setAvatarVoice] = useState("en-US-AvaMultilingualNeural"); // State for the avatar voice
    const [events, setEvents] = useState([]);
    const [recognitionButtonEnabled, setRecognitionButtonEnabled] = useState(false);
    const [currentEventId, setCurrentEventId] = useState(0);
    const [lastSpeakTime, setLastSpeakTime] = useState(0);
    const [iceCredentials, setIceCredentials] = useState([]);
    const [startButtonEnabled, setStartButtonEnabled] = useState(false);

    const values = {
        isThinking,
        setIsThinking,
        isListening,
        setIsListening,
        isSpeaking,
        setIsSpeaking,
        isAvatarStarted,
        setIsAvatarStarted,
        avatarSelection,
        setAvatarSelection,
        avatarCaption,
        setAvatarCaption,
        avatarVoice,
        setAvatarVoice,
        events,
        setEvents,
        recognitionButtonEnabled,
        setRecognitionButtonEnabled,
        currentEventId,
        setCurrentEventId,
        lastSpeakTime,
        setLastSpeakTime,
        isAgentReady,
        setIsAgentReady,
        isAvatarLoading,
        setIsAvatarLoading,
        iceCredentials,
        setIceCredentials,
        startButtonEnabled,
        setStartButtonEnabled
    }

    return (
        <AvatarContext.Provider value={values}>
            {children}
        </AvatarContext.Provider>
    );
};

export const useAvatarContext = () => {
    const context = useContext(AvatarContext);
    if (context === undefined) {
        throw new Error('useAvatarContext must be used within a AvatarProvider');
    }
    return context;
};